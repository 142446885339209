<template>
  <div
    ref="box"
    class="Inventory_box"
  >
    <div id="echarts">
      <div
        ref="MainOne"
        class="main_Echarts_top"
      />
    </div>
  </div>
</template>

<script>
import { GetSatisfactionByALLocation } from '@/api/enterprise/assest'
// import "../../../../assets/map/js/world.js";   //世界地图
// import "../../../../assets/map/js/china.js";   //中国地图
import * as echarts from 'echarts'
import '@/assets/map/worldAndChina' // 世界地图加中国地图
import Vue from 'vue'
// 世界地图 world
// 中国地图 china
// 世界地图加中国地图 worldAndChina
export default {
  name: 'AssetMap',
  created(){
    this.getSatisfactionByALLocation()

  },
  data() {
    const MapList = [ // 获取接口的数据
      {
        region: '北京',
        Urban: '276',
        // Population: '2188.6万',
      }, {
        region: '慕尼黑',
        Urban: '365',
        // Population: '1415万',
      },
      {
        region: '洛杉矶',
        Urban: '651',
        // Population: '1394万',
      },
      {
        region: '当阳',
        Urban: '651',
        // Population: '1394万',
      },
      {
        region: '阿尔及利亚',
        Urban: '651',
        // Population: '1394万',
      },
    ]
    const CountyList = [
      { // 坐标数据
        name: '北京',
        value: [116.20, 39.56],
      },
      {
        name: '当阳',
        value: [111.47, 30.50],
      },
      {
        name: '慕尼黑',
        value: [11.52, 48],
      }, {
        name: '洛杉矶',
        value: [-118.15, 34.04],
      },
      {
        name: '阿尔及利亚',
        value: [3.08,36.42]
      }
    ]
    return {
      MapList: [],
      CountyList: [],
      countryTreeOptions: Vue.prototype.countryTreeOptions
    }
  },
  mounted() {
    // 监听浏览器窗口变化
    window.addEventListener('resize', this.selfAdaption)
    setTimeout(() => {
      this.chinaMap()
    }, 500)
  },
  methods: {
    deepTraversal(data) {
      const result = [];
      data.forEach(item => {
        const loop = data => {
          result.push({
            label: data.label,
            value: data.value,
            location: data.location
          });
          let child = data.children
          if(child){
            for(let i = 0; i < child.length; i++){
              loop(child[i])
            }
          }
        }
        loop(item)
      })
      return result
    },
    getSatisfactionByALLocation(){
      const locations = this.deepTraversal(this.countryTreeOptions)
      GetSatisfactionByALLocation().then(res=>{
        const satisfactionList = res.data.data.satisfactionList
        satisfactionList.forEach(s=>{
          if(s.typeName){
            locations.forEach(l=>{
              if(s.typeName === l.label){
                this.MapList.push({
                  region: l.label,
                  Urban: s.assetsCount,
                })
                this.CountyList.push(
                  {
                    name: l.label,
                    value: l.location,
                  }
                )
              }
            })
          }

        })
      })
    },
    selfAdaption() {
      // let myEchartOne = echarts.init(this.$refs.MainOne);
      const myEchartOne = echarts.init(this.$refs.MainOne)
      myEchartOne.resize()
    },
    // 地图
    chinaMap() {
      const myCharts = echarts.init(this.$refs.MainOne)
      // #region 处理数据，合并，排序
      const geoCoordMap = this.CountyList
      let arrs = this.MapList.map(item => {
        const data = geoCoordMap.find(i => item.region == i.name)
        return { ...item, ...data }
      })
      // 如果不存在区域 手动拼接
      const ConcatArrAy = arrs.concat(geoCoordMap)
      // 去重
      const some = []
      ConcatArrAy.forEach(el => {
        if (!some.some(e => e.name == el.name)) {
          some.push(el)
        }
      })
      arrs = some
      // 排序
      // arrs = arrs.sort((a, b) => a.value[0] - b.value[0])
      if (this.MapList.length == 0) {
        arrs = []
      }
      // #endregion
      const option = {
        geo: {
          map: 'worldAndChina', // 地图格式
          center: [10, 10], // 展示位置
          zoom: 1, // 地图大小
          roam: true, // 禁止缩放平移
          label: {
            // 默认的文本标签显示样式
            show: false,
            zoom: 1.2,
          },
          itemStyle: {
            normal: {
              // 配置地图样式
              color: 'red',
              areaColor: '#e3e6eb',
              borderColor: '#fff',
            },
            // 配置阴影
            emphasis: {
              areaColor: '#d1c7b7', // 选中颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20, // 模糊程度
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
        series: [
          {
            // minAngle: 360, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            // avoidLabelOverlap: true, //是否启用防止标签重叠策略
            labelLine: {
              show: true,
              lineStyle: { color: '#aba5a5' },
              smooth: 0.2,
              length: 0.001,
              // length2: 90, //第二段线的长度
              // backgroundColor: "#fff",
            },
            // center: ["50%", "50%"],
            type: 'scatter',
            coordinateSystem: 'geo', // 表示使用的坐标系为地理坐标系
            labelLayout(params) { // 展示内容的位置
              // // 慕尼黑
              // if (params.dataIndex == 0) {
              //   return {
              //     x: params.rect.x - 150,
              //     y: params.rect.y - 80,
              //   }
              // }
              // //  "北京"
              // if (params.dataIndex == 1) {
              //   return {
              //     x: params.rect.x + 80,
              //     y: params.rect.y - 130,
              //   }
              // }
              // // 洛杉矶
              // if (params.dataIndex == 2) {
              //   return {
              //     x: params.rect.x + 80,
              //     y: params.rect.y - 50,
              //   }
              // }
            },
            label: {
              // formatter: "123",
              normal: {
                // offset:[-18,0],
                position: 'top',
                show: true,
                padding: [10, 10],
                color: '#000',
                areaColor: '#f00',
                backgroundColor: '#fff',
                borderRadius: 4,
                shadowColor: ' rgba(31,39,51,0.1200)',
                shadowBlur: 10,
                // boxShadow: "0px 100px 16px 6px rgba(31,39,51,0.1200)",
                textStyle: {
                  align: 'left',
                  lineHeight: 20,
                },
                formatter(params) {
                  let arr = ''
                  if (
                    params.data.Urban != ''
                    && params.data.Population != ''
                  ) {
                    arr = [
                      params.data.name,
                      `IT资产数:${params.data.Urban}`,
                      // "个人信息字段:" + params.data.Population,
                    ]
                  }
                  return arr.join('\n')
                },

                rich: {
                  a: {
                    height: 20, // 设置字体行高
                  },
                },
              },

              emphasis: {
                show: true,
              },
            },
            // symbolSize: 12,
            itemStyle: {
              color: '#FE7718',
              shadowBlur: 10,
              shadowColor: '#FE7718',
            },
            data: arrs,
          },
        ],
        textStyle: {
          fontSize: 12,
        },
      }
      myCharts.setOption(option, true)
    },
  },
}
</script>

<style lang="scss" scoped>
.Inventory_box {
  width: 100%;
  min-width: 1080px;
  max-width: 2300px;
  max-height: 1200px;
  margin: 0 auto;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -20px;
  margin-bottom: -90px;
  padding: 3px;
  height: calc(100% - 35px);
}

.echarts {
  width: 100%;
  position: relative;
  height: 100%;
  background: white;
}

.main_Echarts_top {
  // min-width: 513px;
  // height: 500px;
  width: 100%;
  aspect-ratio:137/70;
}
</style>
