<template>
  <div>
    <div class="filter-wrap flex-between">
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.SearchStr"
          width="180"
          placeholder="请输入内容"
          clearable
          @clear="handleFilter"
        />
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <div class="text-right">
        <el-button
          v-if="importPerm"
          size="small"
          type="primary"
          icon="el-icon-upload"
          @click="importDialogShow"
        >
          导入
        </el-button>
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>
    </div>
    <div class="table-wrap">
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        border
        :max-height="pageH - 100"
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          label="资产名称"
        />
        <el-table-column
          prop="assetsTypeName"
          :show-overflow-tooltip="true"
          label="资产类型"
        >
          <template
            slot="header"
          >
            <TableHeaderFilterDropdown
              :key-str="'assetsType'"
              :select-list.sync="selectList"
              :label="'资产类型'"
              :value.sync="listQuery.assetsType"
              :dropdown-options="dropdownOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="productNames"
          :show-overflow-tooltip="true"
          label="所属业务"
        >
          <template
            slot="header"
          >
            <TableHeaderFilterDropdown
              :key-str="'productId'"
              :select-list.sync="selectList"
              :label="'所属业务'"
              :value.sync="listQuery.productId"
              :dropdown-options="productOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          :show-overflow-tooltip="true"
          label="资产描述"
        />
        <el-table-column
          prop="responsiblePerson"
          sortable
          label="负责人"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <TableHeaderFilterDropdown
              :key-str="'responsiblePerson'"
              :select-list.sync="selectList"
              :label="'负责人'"
              :value.sync="listQuery.responsiblePerson"
              :dropdown-options="userOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="geographicLocation"
          :show-overflow-tooltip="true"
          sortable
          label="物理位置"
        >
          <template
            slot="header"
          >
            <TableHeaderFilterDropdown
              :key-str="'geographicLocation'"
              :select-list.sync="selectList"
              :label="'物理位置'"
              :value.sync="listQuery.geographicLocation"
              :dropdown-options="positionOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="ipAddress"
          :show-overflow-tooltip="true"
          label="IP端口"
        >
          <template slot-scope="{row}">
            <span v-show="row.ipAddress">{{ row.ipAddress }}</span>
            <span v-show="row.port">:{{ row.port }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="创建时间"
        >
          <template slot-scope="{row}">
            {{ formatDateStr(row.createTime) }}
          </template>

        </el-table-column>
        <el-table-column
          label="操作"
          width="120px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="readPerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="detailDialogShow(row.id)"
            >
              <el-tooltip
                content="查看"
                placement="top"
                effect="light"
              >
                <i class="el-icon-document" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row.id)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="delAssest(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <!--<pagination-->
      <!--v-show="total>0"-->
      <!--:total="total"-->
      <!--:page.sync="listQuery.page"-->
      <!--:limit.sync="listQuery.pageSize"-->
      <!--@pagination="getList"-->
      <!--/>-->
      <PageQuery
        v-show="total>0"
        :total="total"
        :cache-name="'listQuery3'"
        :update-state="'UPDATE_LISTQUERY3'"
        :list-query.sync="listQuery"
        :init-list-query.sync="initListQuery"
        @pagination="getList"
      />
    </div>
    <ImportAssest
      ref="importAssest"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import {
  getAssetsByPage,
  deleteAssets,
  QueryResponsiblePerson,
  GetAssetsALLocation,
  GetAssetsTypeOptions,
} from '@/api/enterprise/assest'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import { GetAssetsProduct } from '@/api/enterprise/product'
import ImportAssest from './ImportAssest.vue'

export default {
  name: 'AssetViewList',
  components: {
    FilterSelectList,
    TableHeaderFilterDropdown,
    ImportAssest,
  },

  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      assetsType: '',
      responsiblePerson: '',
      geographicLocation: '',
      productId: '',
      orderBy: {
        responsiblePerson: '',
      },
    }
    return {
      selectList: [],
      userOptions: [],
      dropdownOptions: [],
      positionOptions: [],
      assestTypeObj: {
        1: '前端应用服务器',
        2: '后端应用服务器',
        3: '中间件',
        4: '数据库',
        5: '文件存储',
        6: '数据备份',
        7: '邮件',
        8: '第三方接口',
        9: '客户端设备',
      },
      importPerm: findBtnPerm('it-asset-4'),
      savePerm: findBtnPerm('it-asset-2'),
      updatePerm: findBtnPerm('it-asset-3'),
      readPerm: findBtnPerm('it-asset-5'),
      delPerm: findBtnPerm('it-asset-6'),
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      productOptions: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.importPerm = verifyBtnPerm(btnPerms, 'it-asset-4')
      this.savePerm = verifyBtnPerm(btnPerms, 'it-asset-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'it-asset-3')
      this.readPerm = verifyBtnPerm(btnPerms, 'it-asset-5')
      this.delPerm = verifyBtnPerm(btnPerms, 'it-asset-6')
    })
    this.getAllProduct()
    this.getAllAssetsTypeList()
    this.getUersSelect()
    const cacheQuery = this.$store.state.pageQuery.listQuery3
    this.listQuery = { ...cacheQuery }
    this.getList()
    this.GetAssetsALLocation()
  },
  methods: {
    getAllProduct() {
      GetAssetsProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(item => {
            this.productOptions.push({
              label: item.name,
              value: item.id,
            })
          })
        }
      })
    },
    getUersSelect() {
      QueryResponsiblePerson().then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach(item => {
            this.userOptions.push({
              label: item,
              value: item,
            })
          })
        }
      })
    },
    GetAssetsALLocation() {
      GetAssetsALLocation().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          if (resData.data.length > 0) {
            resData.data.forEach(r => {
              this.positionOptions.push({ label: r.label, value: r.label })
            })
          }
        }
      })
    },
    delAssest(assestId) {
      this.$confirm('确认删除资产吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteAssets({ id: assestId }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.dialogVisible = false
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    detailDialogShow(assestId) {
      this.$router.push({
        path: '/assetDetail',
        query: {
          id: assestId,
        },
      })
    },
    importDialogShow() {
      this.$refs.importAssest.dialogVisible = true
    },
    saveDialogShow(type, assestId) {
      // eslint-disable-next-line eqeqeq
      if (type == 2) {
        this.$router.push({
          path: '/assestSave',
          query: {
            id: assestId,
          },
        })
      } else {
        this.$router.push({
          path: '/assestSave',
        })
      }
    },
    getAllAssetsTypeList() {
      GetAssetsTypeOptions().then(res => {
        const resData = res.data
        resData.data.forEach(item => {
          this.dropdownOptions.push({
            label: item.name,
            value: item.id,
          })
        })
        console.log(this.dropdownOptions)
      })
    },
    getList() {
      this.loading = true
      this.listQuery.assetsType = this.listQuery.assetsType ? this.listQuery.assetsType : 0
      this.listQuery.productId = this.listQuery.productId ? this.listQuery.productId : 0
      getAssetsByPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = { descending: 'desc', ascending: 'asc' }
      this.listQuery.orderBy[column.prop] = orderObj[order]
      this.getList()
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
