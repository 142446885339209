<template>
  <div>
    <PageIntroduce
      :title="'IT资产管理'"
      :desc="'管理企业与个人信息相关的所有IT软硬件资产。'"
      :tips="'IT资产是数据智能发现的基础'"
    />
    <el-tabs
      v-model="activeName"
      type="border-card"
    >
      <!--<el-tab-pane label="资产图谱"-->
      <!--:lazy="true"-->
      <!--name="1">-->
      <!--<AssetViewChart v-if="activeName == 1"/>-->
      <!--</el-tab-pane>-->
      <!--<el-tab-pane label="资产列表"-->
      <!--:lazy="true"-->
      <!--name="2">-->
      <!--<AssetViewList v-if="activeName == 2"/>-->
      <!--</el-tab-pane>-->
      <el-tab-pane
        v-for="(item,index) in navMenus"
        :label="item.label"
        :lazy="true"
        :name="String(index+1)"
      >
        <AssetViewChart v-if="item.label === '资产图谱'" />
        <AssetViewList v-if="item.label === '资产列表'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import AssetViewList from './AssetViewList'
import AssetViewChart from './AssetViewChart'

export default {
  components: {
    AssetViewChart,
    AssetViewList,
    PageIntroduce,
  },
  data() {
    return {
      activeName: '1',
      navMenus: [],
      fullMenus: ['资产图谱', '资产列表'],
    }
  },
  watch: {
    activeName() {
      sessionStorage.setItem('activeName', this.activeName)
    },
  },
  created() {
    const twoLevelMenus = localStorage.getItem('twoLevelMenus')
    if (twoLevelMenus) {
      const menus = JSON.parse(twoLevelMenus)
      menus.forEach(d => {
        if (this.fullMenus.indexOf(d.name) !== -1) {
          this.navMenus.push({
            label: d.name,
          })
        }
      })
    }
    this.activeName = sessionStorage.getItem('activeName') || '1'
    this.activeName = sessionStorage.getItem('activeName') || '1'
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem('activeName', '1')
    next()
  },
}
</script>
