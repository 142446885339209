<template>
  <b-row class="match-height">
    <b-col md="4">
      <b-card
        v-loading="assestChartLoading"
        no-body
      >
        <b-card-header class="m-0">
          <span class="title font-medium-1">IT资产总数：{{ assestTotal }}</span>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="assestChartInit"
            type="bar"
            height="320"
            class="m-0"
            :options="barChart.chartOptions"
            :series="barChart.series"
          />
          <el-empty
            v-if="assestTotal === 0"
            description="暂无数据"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card
        v-loading="assestChartLoading"
        no-body
      >
        <b-card-header>
          <el-col
            :span="12"
            class="font-medium-1 mt-50"
          >
            <span class="title">IT资产统计</span>
          </el-col>
        </b-card-header>

        <b-card-body>
          <vue-apex-charts
            v-if="assestChartInit"
            type="donut"
            height="320"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          />
          <el-empty
            v-if="donutChart.series.length === 0"
            description="暂无数据"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="20"
            class="font-medium-1 mt-50"
          >
            <span class="title">IT业务系统资产数</span>
          </el-col>
        </b-card-header>

        <b-card-body>
          <vue-apex-charts
            v-if="productChartInit"
            type="bar"
            height="320"
            :options="ITproductChart.chartOptions"
            :series="ITproductChart.series"
          />
          <el-empty
            v-if="ITproductChart.series[0].data.length === 0"
            description="暂无数据"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card
        v-loading="pieLoading1"
        no-body
      >
        <b-card-header>
          <el-col
            :span="12"
            class="font-medium-1 mt-50"
          >
            <span class="title">物理位置占比</span>
          </el-col>
        </b-card-header>

        <b-card-body>
          <vue-apex-charts
            v-if="areChart.series.length > 0"
            type="donut"
            height="320"
            :options="areChart.chartOptions"
            :series="areChart.series"
          />
          <el-empty
            v-if="areChart.series.length === 0"
            description="暂无数据"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card
        v-loading="pieLoading2"
        no-body
      >
        <b-card-header>
          <el-col
            :span="12"
            class="font-medium-1 mt-50"
          >
            <span class="title">业务占比</span>
          </el-col>
        </b-card-header>

        <b-card-body>
          <vue-apex-charts
            v-if="productChartInit"
            type="donut"
            height="320"
            :options="productChart.chartOptions"
            :series="productChart.series"
          />
          <el-empty
            v-if="productChart.series.length === 0"
            description="暂无数据"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12">
      <AssetMap />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BLink, BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import { GetAssetsSatisfaction, GetAssetsSatisfactionByLocation, GetAssetsSatisfactionByProduct } from '@/api/enterprise/assest'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import AssetChartData from './AssetChartData'
import AssetMap from './AssetMap.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,

    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    AssetMap,
  },

  data() {
    const chartColors = {
      column: {
        series1: '#826af9',
        series2: '#d2b0ff',
        bg: '#f8d3ff',
      },
      success: {
        shade_100: '#7eefc7',
        shade_200: '#06774f',
      },
      donut: {
        series1: '#ffe700',
        series2: '#00d4bd',
        series3: '#826bf8',
        series4: '#2b9bf4',
        series5: '#FFA1A1',
      },
      area: {
        series3: '#a4f8cd',
        series2: '#60f2ca',
        series1: '#2bdac7',
      },
    }
    const responsive = [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1.5rem',
                  },
                  value: {
                    fontSize: '1rem',
                  },
                  total: {
                    fontSize: '1.5rem',
                  },
                },
              },
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ]
    const plotOptions = {
      pie: {
        customScale: 1,
        donut: {
          size: '45%',
          labels: {
            show: true,
            name: {
              fontSize: '14px',
            },
            value: {
              fontSize: '14px',
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}`
              },
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: '14px',
              label: '总计',
            },
          },
        },
      },
    }
    const legend = {
      show: true,
      position: 'bottom',
      fontSize: '14px',
    }
    const dataLabels = {
      enabled: true,
      fontSize: '12px',
      formatter(val) {
        // eslint-disable-next-line radix
        return `${parseInt(val)}%`
      },
    }
    return {
      AssetChartData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      assestChartLoading: false,
      assestChartInit: false,
      productChartInit: false,
      pieLoading1: false,
      pieInit1: true,
      pieInit2: true,
      pieLoading2: false,
      barChart: {
        series: [
          {
            name: '',
            // data: [700, 350, 480, 600, 210, 550, 150],
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '40%',
              dataLabels: {
                position: 'top',
              },
              endingShape: 'rounded',
            },
          },
          tooltip: {
            y: {
              formatter(val) {
                return val
              },
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: 20,
            style: {
              fontSize: '12px',
              fontWeight: 'null',
              colors: ['#6e6b7b'],
            },
          },
          xaxis: {
            // categories: ['前端应用服务器', '后端应用服务器', '中间件', '数据库', '文件存储', '数据备份', '其他'],
            categories: [],
            // min: 1,
          },
          yaxis: {

          },
        },
      },
      assestTotal: 0,
      donutChart: {
        // series: [85, 16, 50, 50],
        series: [],
        chartOptions: {
          legend,
          colors: [
            chartColors.donut.series3,
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series2,
          ],
          dataLabels,
          plotOptions,
          // labels: ['前端应用服务器', '后端应用服务器', '数据库', '其他'],
          labels: [],
          responsive,
        },
      },
      areChart: {
        // series: [85, 16, 50, 50],
        series: [],
        chartOptions: {
          legend,
          colors: [
            chartColors.donut.series3,
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series2,
          ],
          dataLabels,
          plotOptions,
          // labels: ['前端应用服务器', '后端应用服务器', '数据库', '其他'],
          labels: [],
          responsive,
        },
      },
      ITproductChart: {
        series: [
          {
            name: '',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.error,
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              columnWidth: '30%',
              endingShape: 'rounded',
              dataLabels: {
                position: 'top',
              },
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              fontWeight: 'null',
              colors: ['#6e6b7b'],
            },
          },
          xaxis: {
            categories: [],
          },
          tooltip: {
            y: {
              formatter(val) {
                return val
              },
            },
          },
        },
      },
      productChart: {
        // series: [85, 16, 50, 50],
        series: [],
        chartOptions: {
          legend,
          colors: [
            chartColors.donut.series3,
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series2,
            chartColors.donut.series1,
          ],
          dataLabels,
          plotOptions,
          // labels: ['前端应用服务器', '后端应用服务器', '数据库', '其他'],
          labels: [],
          responsive,
        },
      },
    }
  },
  created() {
    this.getAssetsSatisfaction()
    this.getAssetsSatisfactionByLocation()
    this.getAssetsSatisfactionByProduct()
  },
  methods: {
    //
    getAssetsSatisfactionByProduct() {
      this.pieLoading2 = true
      GetAssetsSatisfactionByProduct(10).then(res => {
        this.pieLoading2 = false
        if (res.data.code === 0) {
          const { satisfactionList } = res.data.data
          if (satisfactionList.length) {
            satisfactionList.forEach(r => {
              this.productChart.series.push(r.assetsCount)
              this.productChart.chartOptions.labels.push(r.typeName)
              this.ITproductChart.series[0].data.push(r.assetsCount)
              this.ITproductChart.chartOptions.xaxis.categories.push(r.typeName)
            })
            this.productChartInit = true
          }
        }
      })
    },
    getAssetsSatisfactionByLocation() {
      this.pieLoading1 = true
      GetAssetsSatisfactionByLocation(10).then(res => {
        this.pieLoading1 = false
        if (res.data.code === 0) {
          const { satisfactionList } = res.data.data
          satisfactionList.forEach(r => {
            this.areChart.series.push(r.assetsCount)
            this.areChart.chartOptions.labels.push(r.typeName)
          })
        }
      })
    },
    getAssetsSatisfaction() {
      this.assestChartLoading = true
      GetAssetsSatisfaction().then(res => {
        this.assestChartLoading = false
        if (res.data.code == 0) {
          const { satisfactionList } = res.data.data
          this.assestTotal = res.data.data.total
          if (satisfactionList.length) {
            satisfactionList.forEach(r => {
              this.barChart.series[0].data.push(r.assetsCount)
              this.barChart.chartOptions.xaxis.categories.push(r.typeName)
              this.donutChart.series.push(r.assetsCount)
              this.donutChart.chartOptions.labels.push(r.typeName)
            })
            this.assestChartInit = true
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

/*title*/
.title {
  border-left: solid 6px #0d40b2;
  padding-left: 8px;
  margin-bottom: 2px;
}
</style>
