<template>
    <el-dialog
            title="导入"
            :visible.sync="dialogVisible"
            width="620px"
            @close="dialogClose"
            @open="dialogOpen"
    >
        <el-form
            v-loading="loading"
            v-if="dialogVisible"
            ref="form"
            :model="form"
            class="assest-save-form mr10"
            label-width="100px"
        >
            <el-form-item label="上传文件" prop="fileName" :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]">
                <input  accept=".xls,.xlsx" type="file" id="uploadFileInput" style="display: none" @change="uploadFileInputChange">
                <el-input disabled placeholder="请上传文件"   class="upload-file-input"  v-model="form.fileName">
                    <template slot="append">
                        <div style="cursor: pointer;" @click="uploadFileClick">选择文件</div>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item label="下载模板">
                <el-link type="primary" @click="downloadTemplate">
                    下载模板
                    <i class="el-icon-download"></i>
                </el-link>
            </el-form-item>
        </el-form>
        <div
                slot="footer"
                class="dialog-footer"
        >
            <el-button @click="dialogVisible = false">
                取 消
            </el-button>
            <el-button
                    type="primary"
                    :disabled="loading"
                    @click="submitForm"
            >
                提 交
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
  import { success, error } from '@core/utils/utils'
  import { ImportAssetsInfo,DownloadAssetsImportTemplate } from '@/api/enterprise/assest'
  export default {
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          fileName: ''
        },
      }
    },
    methods: {
      downloadTemplate(){
        DownloadAssetsImportTemplate()
      },
      uploadFileClick(){
        document.getElementById('uploadFileInput').click()
      },
      uploadFileInputChange(){
        const uploadDom = document.getElementById('uploadFileInput')
        const fileVal = uploadDom.files
        this.form.fileName = fileVal[0].name
      },
      dialogOpen() {

      },
      dialogClose() {
        this.form = {
          fileName: '',
        }
        this.$refs.form.resetFields()
      },
      // eslint-disable-next-line vue/no-dupe-keys
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.form.validate(valid => {
          // eslint-disable-next-line no-empty
          if (valid) {
            this.loading = true
            const uploadDom = document.getElementById('uploadFileInput')
            const formData = new FormData()
            formData.append('file', uploadDom.files[0])
            ImportAssetsInfo(formData).then(res=>{
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            }).catch(e=>{
              error('服务错误')
              this.loading = false
            })
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style>
    .assest-save-form{
        padding-right: 10px;
    }
    .assest-save-form .el-icon-question{
        position: absolute;
        top: 14px;
        right: -20px;
    }
</style>
